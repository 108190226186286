import PropTypes from 'prop-types'
import React from 'react'

const Section = ({ children, title }) => {
  return (
    <section>
      <div className="bg-theme-gray">
        <div className="px-6 py-16 mx-auto lg:container">
          {title && (
            <h2>{title}</h2>
          )}
          {children}
        </div>
      </div>
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default Section
