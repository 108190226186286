/**
 * Returns the data used by the footer
 */
const useFooterData = () => {
  const footerData = {
    horaire: `Lundi au vendredi:
    08h00 - 12h00 et 14h00 - 18h30
    `,
    adresse: `Papeterie d'Aubonne
    Place de l'Ancienne-Gare 1
    1170 Aubonne, Suisse`,
    newsletter: `Inscrivez-vous à notre newsletter pour que nous vous tenions informé des dernières nouvelles relative à la Papeterie d'Aubonne`,
    copyright: `Copyright © Office Global de la Côte ${new Date().getFullYear()}. Tous droits réservés`,
  }

  return footerData
}

export default useFooterData
