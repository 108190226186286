import React from 'react'
import useFooterData from '../../hooks/use-footer-data'
import FormInput from '../forms/form-input'
import FormSubmit from '../forms/form-submit'

const Footer = () => {
  const { horaire, adresse, newsletter, copyright } = useFooterData()

  return (
    <footer>
      <div className="bg-theme-blue text-theme-gray">
        <div className="px-6 py-16 mx-auto lg:container">
          <div className="w-full flex flex-row flex-wrap space-y-5 md:space-y-0">
            <div className="w-full md:w-1/2 md:order-last">
              <h3>Newsletter</h3>
              <p>{newsletter}</p>
              <form className="mt-3 space-y-3 sm:space-y-0 sm:flex">
                <FormInput
                  name="email"
                  type="email"
                  placeholder="Adresse email"
                  className="sm:w-full sm:mr-2"
                />
                <FormSubmit value="S'inscrire" className="btn-red" />
              </form>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/4 md:pr-3">
              <h3>Horaire</h3>
              <p>{horaire}</p>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/4 md:pr-3">
              <h3>Adresse</h3>
              <p>{adresse}</p>
            </div>
          </div>
          <p className="mt-8 md:mt-16 text-center font-light">{copyright}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
