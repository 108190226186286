import React from 'react'
import PropTypes from 'prop-types'

const FormInput = ({ name, type, placeholder, className }) => {
  return (
    <label className={className && className}>
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        className="input w-full rounded shadow-md bg-theme-gray text-theme-black"
      />
    </label>
  )
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default FormInput
