import React from 'react'
import PropTypes from 'prop-types'

const FormSubmit = ({ value, className }) => {
  return (
    <input name="submit" type="submit" value={value} className={`btn ${className && className}`} />
  )
}

FormSubmit.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
}

export default FormSubmit
