import React, { useState } from 'react'
import { Link } from 'gatsby'
import useNavigation from '../../hooks/use-navigation'

import Hamburger from 'hamburger-react'
import Logo from '../../images/oglc-logo.png'

/**
 * Site header comprising logo and navigation
 */
function Header() {
  const [isOpen, setOpen] = useState(false)
  const navigationItems = useNavigation()

  return (
    <header>
      <div className="w-full bg-white shadow fixed">
        <div className="mx-auto px-6 lg:container md:flex items-center justify-between">
          <div className="h-24 flex items-center justify-between">
            <h1 className="hidden">Papeterie d&apos;Aubonne</h1>
            <Link to="/" title={`Aller à la page d'accueil`}>
              <img
                className="w-64"
                src={Logo}
                alt="Logo de la Papeterie d'Aubonne"
              />
            </Link>
            <div
              title={isOpen ? 'Fermer le menu' : 'Afficher le menu'}
              className="block md:hidden text-theme-blue"
            >
              <Hamburger
                toggled={isOpen}
                toggle={setOpen}
                size={40}
                rounded
                direction="left"
                label="Afficher le menu"
              />
            </div>
          </div>
          <div
            className={`w-full h-0 ${
              isOpen ? 'h-56' : ''
            } overflow-hidden transition-all duration-500 ease-in-out space-y-4 flex flex-col
            md:items-center md:flex-row md:h-24 md:w-auto md:space-y-0 md:space-x-6`}
          >
            {navigationItems.map(item => (
              <Link
                key={item.title}
                to={item.path}
                title={`Aller à la page ${item.title.toLowerCase()}`}
                className="text-lg"
                activeClassName="font-bold"
              >
                {item.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
