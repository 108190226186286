import React from 'react'

// import { usePages } from '../hooks/use-pages'

import Layout from '../components/layout/'
import Section from '../components/layout/section'
// import Banner from '../components/layout/banner'
import SEO from '../components/seo'

import Image from '../images/logistics.svg'
import { ExternalLinkIcon } from '@heroicons/react/solid'

function IndexPageTemplate() {
  return (
    <>
      { /* <Banner message="La Papeterie sera fermée du 15 au 18 avril durant les fêtes de Pâques." /> */ }
      <Section title="Site en construction">
        <div className="flex flex-col-reverse justify-between md:flex-row">
          <div className="max-w-2xl">
            <p>Pour mieux vous servir, ce site internet est en cours de modernisation.
              Notre boutique en ligne reste disponible à l&apos;adresse suivante:{' '}
              <a href="https://shop.papeterie-aubonne.ch" className="link">
                https://shop.papeterie-aubonne.ch
                <ExternalLinkIcon className="w-4 inline" />
              </a>
            </p>
            <br />
            <p>Le personnel de la Papeterie d&apos;Aubonne se tient à votre disposition au magasin. Pour tout besoin, vous pouvez aussi nous contacter par téléphone ou par
              email.
            </p>
            <br />
            <h3>Contact</h3>
            <p>
              Email :{' '}
              <a href="mailto:info@papeterie-aubonne.ch">
                info@papeterie-aubonne.ch
              </a>
            </p>
            <p>
              Tel. : <a href="tel:+41218085888">+41 21 808 58 88</a>
            </p>
            <br />
            <h3>Adresse</h3>
            <p>Papeterie d&apos;Aubonne</p>
            <p>Place de l&apos;Ancienne-Gare 1</p>
            <p>1170 Aubonne, Suisse</p>
            <br />
            <h3>Horaire</h3>
            <p>Du lundi au vendredi de <strong>08h00</strong> à <strong>12h00</strong> et de <strong>14h00</strong> à <strong>18h30</strong></p>
          </div>
          <div className="py-4 md:py-0">
            <img className="w-96" src={Image} alt="Image décorative" />
          </div>
        </div>
      </Section>
    </>
  )
}

function IndexPage() {
  // Get page data from use-pages hook
  // const { home: data } = usePages()
  // console.log(data)

  return (
    <Layout>
      <SEO
        keywords={[
          `Papeterie d'Aubonne`,
          `Office Global de la Côte`,
          `OGLC`,
          `Vaud`,
          `Aubonne`,
          `Magasin`,
          `Boutique en ligne`,
          `Bureautique`,
          `Informatique`,
          `Matériel scrolaire`,
        ]}
        title="Accueil"
      />
      <IndexPageTemplate />
    </Layout>
  )
}

export default IndexPage
