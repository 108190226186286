/**
 * Returns the navigational links as an array of
 * objects comprised of a title and a path
 */
const useNavigation = () => {
  const navigationItems = [
    {
      title: 'Accueil',
      path: '/',
    },
    {
      title: 'Shop',
      path: 'https://shop.papeterie-aubonne.ch/fr/',
    },
    {
      title: 'Services',
      path: '/#',
    },
    {
      title: 'Contact',
      path: '/#',
    },
  ]

  return navigationItems
}

export default useNavigation
