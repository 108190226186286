import PropTypes from 'prop-types'
import React from 'react'
import Header from './header'
import Footer from './footer'

function Layout({ children }) {
  return (
    <div className="font-sans bg-white">
      <Header />
      <main>
        <div className="pt-24">{children}</div>
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
